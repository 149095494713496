import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Applications",
        translate: "NAV_APPLICATIONS",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                translate: "NAV_DASHBOARD",
                type: "item",
                icon: "dashboard",
                url: "admin/dashboard",
            },
            {
                id: "magasin",
                title: "Magasins",
                translate: "NAV_SAMPLE_MAGASINS",
                type: "item",
                icon: "store",
                url: "admin/magasin",
            },
            {
                id: "notifications",
                title: "Notifications",
                translate: "NAV_SAMPLE_NOTIFICATIONS",
                type: "item",
                icon: "notifications",
                url: "admin/notification",
            },
            {
                id: "translationgroup",
                title: "Translations",
                translate: "NAV_SAMPLE_TRANSLATIONS",
                type: "collapsable",
                icon: "translate",
                children: [
                    {
                        id: "languages",
                        title: "Languages",
                        translate: "NAV_SAMPLE_LANGUAGES",
                        type: "item",
                        icon: "flag",
                        url: "admin/languages",
                    },
                    {
                        id: "translation",
                        title: "Translations",
                        translate: "NAV_SAMPLE_TRANSLATIONS",
                        type: "item",
                        icon: "translate",
                        url: "admin/translations",
                    },
                ],
            },
            {
                id: "user",
                title: "Utilisateurs",
                translate: "NAV_SAMPLE_UTILISATEURS",
                type: "collapsable",
                icon: "group",
                children: [
                    {
                        id: "admin",
                        title: "Admin",
                        translate: "NAV_SAMPLE_ADMIN",
                        type: "item",
                        icon: "account_circle",
                        url: "admin/users",
                    },
                    {
                        id: "devices",
                        title: "Devices",
                        translate: "NAV_SAMPLE_DEVICES",
                        type: "item",
                        icon: "smartphone",
                        url: "admin/devices",
                    },
                ],
            },
        ],
    },
    {
        id       : 'configuration_mobile',
        title    : 'Mobile',
        translate: 'NAV_CONFIG_MOBILE',
        type     : 'group',
        children : [
            {
                id       : 'settings',
                title    : 'Settings',
                translate: 'MOBILE_SETTING',
                type     : 'collapsable',
                icon     : 'build',
                children : [
                    {
                        id       : 'settings_listing',
                        title    : 'List',
                        translate: 'MOBILE_SETTING_LIST',
                        type     : 'item',
                        icon     : 'list',
                        url      : 'admin/settings',
                    },
                ]
            }, 
            {
                id       : 'activities',
                title    : 'Activités',
                translate: 'NAV_SAMPLE_ACTIVITES',
                type     : 'collapsable',
                icon     : 'extension',
                children : [
                    {
                        id       : 'evenement',
                        title    : 'Evenement',
                        translate: 'NAV_SAMPLE_EVENEMENT',
                        type     : 'item',
                        icon     : 'event', 
                        url      : 'admin/evenement',   
                    },
                    {
                        id       : 'offres',
                        title    : 'Offres',
                        translate: 'NAV_SAMPLE_OFFRES',
                        type     : 'item',
                        icon     : 'local_offer', 
                        url      : 'admin/offres',   
                    },
                    {
                        id       : 'service',
                        title    : 'Services',
                        translate: 'NAV_SAMPLE_SERVICES',
                        type     : 'item',
                        icon     : 'build', 
                        url      : 'admin/services',   
                    }
                ]
            },
            {
                id       : 'mobil',
                title    : 'Mobile activité',
                translate: 'NAV_SAMPLE_MOBILE',
                type     : 'collapsable',
                icon     : 'devices',
                children : [
                    {
                        id       : 'form',
                        title    : 'Form',
                        translate: 'NAV_SAMPLE_FORM',
                        type     : 'item',
                        icon     : 'web_asset', 
                        url      : 'admin/form',   
                    },
                       
                    {
                        id       : 'menus',
                        title    : 'Menus',
                        translate: 'NAV_SAMPLE_MENUS',
                        type     : 'item',
                        icon     : 'menu',
                        url      : 'admin/menus', 
                    },
                    {
                        id       : 'pages',
                        title    : 'Pages',
                        translate: 'NAV_SAMPLE_PAGES',
                        type     : 'item',
                        icon     : 'view_quilt', 
                        url      : 'admin/pages',   
                    },
                ]
            }, 
            {
                id       : 'Carrousel',
                title    : 'Mobile slider',
                translate: 'NAV_SAMPLE_MOBILESLIDE',
                type     : 'collapsable',
                icon     : 'view_carousel',
                children : [
                    {
                        id       : 'carrousel',
                        title    : 'Carrousel',
                        translate: 'NAV_SAMPLE_CAROUSEL',
                        type     : 'item',
                        icon     : 'view_carousel',
                        url      : 'admin/carrousel', 
                    },
                    {
                        id       : 'bannieres',
                        title    : 'Bannières',
                        translate: 'NAV_SAMPLE_BANNIERE',
                        type     : 'item',
                        icon     : 'image',
                        url      : 'admin/bannieres', 
                    },
                    {
                        id       : 'categories',
                        title    : 'Categories',
                        translate: 'NAV_SAMPLE_CATEGORIES',
                        type     : 'item',
                        icon     : 'apps',
                        url      : 'admin/category', 
                    },
               ]
            },
        ]
    }
];

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { navigation } from 'app/navigation/navigation';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LanguagesService } from './services/languages.service';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    is_ready: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,        
        private _platform: Platform,
        private _httpClient: HttpClient,
        private _languagesService : LanguagesService,
    )
    {
        this._languagesService.init().then(async () => {
            // Get default navigation
            this.navigation = navigation;

            // Register the navigation to the service
            this._fuseNavigationService.register('main', this.navigation);

            // Set the main navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('main');
            this.is_ready = true;
            await this.addSettingsNavigation();
            await this.addNewNavigation();
            this._fuseSplashScreenService.hide();
        }) 

        

        
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }
                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Remove the custom function menu
        this._fuseNavigationService.removeNavigationItem('configuration');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    addNewNavigation(){
        // Add customize nav item that opens the bar programmatically
        const customFunctionNavItem = {
            id       : 'configuration',
            title    : 'Caroussels',
            translate: 'NAV_CONFIG_CAROUSSEL',
            type     : 'group',
            children : [
                
            ]
        };

        const children = {
            id       : 'products',
            title    : 'Produits',
            translate: 'PRODUCTS',
            type     : 'collapsable',
            icon     : 'label', 
            children : []   
        }
        return new Promise((resolve) => {
            this._httpClient.get(`${environment.api}get/selection/categories`)
            .subscribe((response: Array<any>) => {
                response.map(async (r:any) => {
                    children.children.push(
                        {
                            id       : 'category'+r.id,
                            title    : await this._languagesService.translate(r.name),
                            translate: r.name,
                            type     : 'item',
                            icon     : 'label_important', 
                            url      : `admin/products/${r.id}?${r.name}`,   
                        }
                    )
                });
                customFunctionNavItem.children.push(children)
                this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');   
                resolve(true);
            }, _ => {});
        })
    }

    addSettingsNavigation() {
        return new Promise((resolve) => {
            this._httpClient.get(`${environment.api}setting-menu`)
            .subscribe(async (response: Array<any>) => {
                response.map(async (r:any) => {
                    let icon = 'label_important';
                    let url = 'admin/settings';
                    if(r.is_active == 1) {
                        if(r.module === "BRANDS") return;
                        if(r.module === "SOCIAL_NETWORK") {
                            icon = 'share';
                            url = 'admin/social-network';
                        } else if(r.module === "DIGIT_CARD") {
                            icon = 'credit_card';
                            url = 'admin/digitcard';
                            await this._fuseNavigationService.addNavigationItem({
                                id       : 'ANCIENNE_CARTE',
                                title    : await this._languagesService.translate('ANCIENNE_CARTE'),
                                translate: 'ANCIENNE_CARTE',
                                type     : 'item',
                                icon     : icon, 
                                url      : 'admin/old-digit-card',
                            }, 'settings');  
                        }
                        await this._fuseNavigationService.addNavigationItem({
                            id       : r.module,
                            title    : await this._languagesService.translate(r.module),
                            translate: r.module,
                            type     : 'item',
                            icon     : icon, 
                            url      : url,
                        }, 'settings');   
                    }
                });   
                resolve(true)                          
            }, _ => {});
        })
    }
}
